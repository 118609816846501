import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { User } from 'src/app/pojos/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'newuser',
  templateUrl: './newuser.component.html',
  styleUrls: ['./newuser.component.scss']
})
export class NewuserComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter<boolean>();
  @Output("createdNewUser") createdNewUser = new EventEmitter<User>();
  public newUserForm:FormGroup;
  public company:string;

  constructor(private fb:FormBuilder,private userService:UserService,private authenticationService:AuthenticationService,
    private alertService: AlertService) {
      const userNameCompanyArr = authenticationService.extractUserCompany();
    this.company = userNameCompanyArr[1];
  }

  ngOnInit(): void {
    this.newUserForm = this.fb.group({
      "firstName": [null, Validators.required],
      "lastName": [null, Validators.required],
      "company": [{value: this.company, disabled: true}, Validators.required],
      "contactNo": [null, Validators.required],
      "email": [null, Validators.required],
      "role": [{value: "User", disabled: true}, Validators.required], //New USers have role as Admin
      "address": [null, Validators.required]
      });
  }

  toggleModal(){
    this.closeModalEvent.emit();
  }

  addNewUser(newUserForm){
     // console.log("newitemEditForm",this.itemEditForm.getRawValue());
  
    this.userService.createNewUser(newUserForm.getRawValue()).subscribe(
      (data)=>{
        console.log("newUser",data);
        let newUser = new User(data["userId"],data["userName"], data["firstName"],data["lastName"],this.company,data["phoneNumber"],data["email"],
                      "User",data["address"]);
        this.alertService.clear();
        this.alertService.success("Successfully created User",
        { autoClose: true });
        this.createdNewUser.emit(newUser);
      },
      (error)=>{
        this.closeModalEvent.emit();
        this.alertService.clear();
        this.alertService.error("Unable to create new User",
        { autoClose: true });
        throwError("Unable to create User");
      }
    )
    
    
    
    
    
    //
    
  }



}
