
<div class="h-full p-8 overflow-x-hidden overflow-y-auto bg-white rounded-sm shadow-sm">
    <form name="orderNewForm" id="orderNewForm" [formGroup]="orderNewForm" (ngSubmit)="isNewMode ? submitOrderNewForm():submitOrderEditForm()">
        <div class="flex items-center space-x-2 font-semibold leading-8 text-gray-900">
            <span class="text-green-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                </svg>
            </span>
            <span *ngIf="!isEditMode && isNewMode" class="text-2xl font-bold leading-relaxed tracking-wide text-gray-800 lg:text-4xl xs:text-3xl font-primary">New Order</span>
            <span *ngIf="isEditMode && !isNewMode" class="text-2xl font-bold leading-relaxed tracking-wide text-gray-800 lg:text-4xl xs:text-3xl font-primary">Order #{{order.id}}</span>
        </div>
        <!-- <div class="text-gray-700">
            <div class="grid text-sm md:grid-cols-2">
                <div *ngIf="!isNewMenuMode" class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Id</div>
                    <div class="px-4 py-2">{{menu.id}}</div>
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Location</div>
                    <div *ngIf="!isNewMenuMode" class="px-4 py-2">{{menu.location}}</div>
                    <div *ngIf="isNewMenuMode" class="px-4 py-2">1</div>
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Price</div>
                    <input *ngIf="!isNewMenuMode" id="menu-price" formControlName="menuPrice" name="menuPrice" class="px-4 py-2">
                    <input *ngIf="isNewMenuMode" id="menu-price" formControlName="menuPrice" name="menuPrice" class="px-4 py-2" placeholder="Menu Price">
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Discount</div>
                    <input *ngIf="!isNewMenuMode" id="menu-discount" formControlName="menuDiscount" name="menuDiscount" class="px-4 py-2">
                    <input *ngIf="isNewMenuMode" id="menu-discount" formControlName="menuDiscount" name="menuDiscount" class="px-4 py-2" placeholder="Menu Discount">
                </div>
            </div>
        </div> -->
    
        <div class="mt-6 font-primary">
            <div class="flex flex-row">
                <span class="text-xl font-bold leading-relaxed tracking-wide text-gray-800 lg:text-3xl xs:text-2xl font-primary">Menus</span>
                <!-- <button class="flex items-center px-4 py-2 mx-4 font-bold text-white bg-green-800 rounded hover:bg-green-700 max-w-fit"> -->
                    <svg (click)="addMenu()" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-4 cursor-pointer fill-green-600 hover:fill-red-600" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                <!-- </button> -->
            </div>
            <!-- <form formGroupName="itemsForm"> -->
                <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table class="w-full text-sm text-left text-gray-500" formArrayName="menus">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Name
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    <span class="sr-only">Delete</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let menuForm of menus.controls;let i = index">
                                <tr [formGroup]="menuForm" class="bg-white border-b hover:bg-gray-50 ">
                                    <td class="px-6 py-4">
                                            <select *ngIf="!isEditMode && isNewMode" name="menuSelect" formControlName="menuSelect" class="form-select">
                                                <option disabled [ngValue]="null" [selected]="true">Select text</option>
                                                 <ng-container *ngFor="let menu of menusList">
                                                    <option [ngValue]="menu">
                                                        {{menu.name}}
                                                    </option>
                                                </ng-container>
                                            </select>

                                            <select *ngIf="isEditMode && !isNewMode" name="menuSelect" formControlName="menuSelect" class="form-select">
                                                <option *ngIf="!menuForm.controls.menuSelect.value" disabled [ngValue]="null" [selected]="true">Select text</option>
                                                <ng-container *ngIf="menuForm.controls.menuSelect.value">
                                                    <option [ngValue]="menuForm.controls.menuSelect.value" [selected]="true">
                                                        {{menuForm.controls.menuSelect.value.name}}
                                                    </option>
                                                </ng-container>

                                                 <ng-container *ngFor="let menu of menusList">
                                                    <option *ngIf="!menuForm.controls.menuSelect.value || (menuForm.controls.menuSelect.value && menu.id!=menuForm.controls.menuSelect.value.id)" [ngValue]="menu">
                                                        {{menu.name}}
                                                    </option>
                                                </ng-container>
                                            </select>
                                    </td>
                                    <td class="px-6 py-4 text-right">
                                        <a class="font-medium text-blue-600 hover:underline" (click)="deleteMenu(i)">Delete</a>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            <!-- </form> -->
        </div>
    </form>
</div>

<footer class="flex flex-row-reverse w-full h-16 bg-slate-200">
    <button class="px-3 text-sm font-semibold text-green-800 rounded-sm w-fit mx-7 hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs" (click)="toggleModal()">Close</button>
    <button type="submit" *ngIf="!isEditMode && isNewMode" form="orderNewForm" class="px-3 text-sm font-semibold text-blue-800 rounded-sm w-fit mx-7 hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs">Create</button>
    <button type="submit" *ngIf="isEditMode && !isNewMode" form="orderNewForm" class="px-3 text-sm font-semibold text-blue-800 rounded-sm w-fit mx-7 hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs">Save</button>
</footer>