<!-- <section class="pt-16"> -->
    <div class="h-full p-8 bg-white rounded-sm shadow-sm">
        <div class="flex items-center space-x-2 font-semibold leading-8 text-gray-900">
            <span class="text-green-500">
                <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
            </span>
            <span class="tracking-wide">Add User</span>
        </div>
        <div class="text-gray-700">            
            <form name="newUserForm" id="newUserForm" [formGroup]="newUserForm" class="grid gap-4 text-sm md:grid-cols-3" (ngSubmit)="addNewUser(newUserForm)">
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">First Name</div>
                    <input id="first-name" formControlName="firstName" name="first-name" type="text" class="px-4 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Last Name</div>
                    <input id="last-name" formControlName="lastName" name="last-name" type="text" class="px-4 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Company</div>
                    <input id="company" formControlName="company" name="company" type="text" class="px-4 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md opacity-50 appearance-none cursor-not-allowed sm:text-sm">
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Contact No.</div>
                    <input id="contactNo" formControlName="contactNo" name="contactNo" type="text" class="px-4 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Email</div>
                    <input id="email" formControlName="email" name="email" type="text" class="px-4 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md opacity-50 appearance-none sm:text-sm">
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Role</div>
                    <input id="role" formControlName="role" name="role" type="text" class="px-4 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md opacity-50 appearance-none cursor-not-allowed sm:text-sm">
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Address</div>
                    <textarea id="address" formControlName="address" name="address" type="text" class="px-4 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none resize-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"></textarea>
                </div>
            </form>
        </div>
    </div>
<!-- </section> -->
<footer class="flex flex-row-reverse w-full h-16 bg-slate-200">
    <button class="px-3 text-sm font-semibold text-green-800 rounded-sm w-fit mx-7 hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs" (click)="toggleModal()">Close</button>
    <button type="submit" form="newUserForm" class="px-3 text-sm font-semibold text-blue-800 rounded-sm w-fit mx-7 hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs">Save</button>
</footer>