<!-- <div *ngIf="!isEditMode" class="w-full h-full p-8 overflow-x-hidden overflow-y-auto bg-white rounded-sm shadow-sm">
    <div class="flex items-center space-x-2 font-semibold leading-8 text-gray-900">
        <span class="text-green-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
            </svg>
        </span>
        <span class="text-2xl font-bold leading-relaxed tracking-wide text-gray-800 lg:text-4xl xs:text-3xl font-primary">{{item.name}}</span>
    </div>
    <div class="text-gray-700">
        <div class="grid text-sm md:grid-cols-2">
            <div class="grid grid-cols-1">
                        <div class="px-4 py-2 font-semibold">Id</div>
                        <div class="px-4 py-2">{{item.id}}</div>
            </div>
            <div class="grid grid-cols-1">
                <div class="px-4 py-2 font-semibold">Price</div>
                <div class="px-4 py-2">{{item.price}}</div>
            </div>
        </div>
    </div>
</div> -->


<div *ngIf="isEditMode" class="h-full p-8 overflow-x-hidden overflow-y-auto bg-white rounded-sm shadow-sm">
    <form name="itemEditForm" id="itemEditForm" [formGroup]="itemEditForm" (ngSubmit)="isNewItemMode ? itemNewForm():itemEdit()">
        <div class="flex items-center space-x-2 font-semibold leading-8 text-gray-900">
            <span class="w-8 h-8">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                </svg> -->
                <img class="rounded-t-lg" src="../../../assets/images/item-{{randomInt}}.svg" alt="" />
            </span>
            <input *ngIf="!isNewItemMode" id="item-name" formControlName="itemName" name="itemName" class="text-2xl font-bold leading-relaxed tracking-wide text-gray-800 lg:text-4xl xs:text-3xl font-primary" placeholder="{{item.name}}">
            <input *ngIf="isNewItemMode" id="item-name" formControlName="itemName" name="itemName" class="text-2xl font-bold leading-relaxed tracking-wide text-gray-800 lg:text-4xl xs:text-3xl font-primary" placeholder="Item Name">
        </div>
        <div class="text-gray-700">
            <div class="grid text-sm md:grid-cols-2">
                <div *ngIf="!isNewItemMode" class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Id</div>
                    <div class="px-4 py-2">{{item.id}}</div>
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Price</div>
                    <input *ngIf="!isNewItemMode" id="item-price" formControlName="itemPrice" name="itemPrice" class="px-4 py-2">
                    <input *ngIf="isNewItemMode" id="item-price" formControlName="itemPrice" name="itemPrice" class="px-4 py-2" placeholder="Item Price">
                </div>
            </div>
        </div>
    </form>
</div>

<footer class="flex flex-row-reverse w-full h-16 bg-slate-200">
    <button class="px-3 text-sm font-semibold text-green-800 rounded-sm w-fit mx-7 hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs" (click)="toggleModal()">Close</button>
    <button *ngIf="isEditMode && !isNewItemMode" type="submit" form="itemEditForm" class="px-3 text-sm font-semibold text-blue-800 rounded-sm w-fit mx-7 hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs">Save</button>
    <button *ngIf="isEditMode && isNewItemMode" type="submit" form="itemEditForm" class="px-3 text-sm font-semibold text-blue-800 rounded-sm w-fit mx-7 hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs">Create</button>
</footer>
