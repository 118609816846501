import { ItemsComponent } from './items/items.component';
import { LandingComponent } from './landing/landing.component';
import { SigninComponent } from './signin/signin.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignupComponent } from './signup/signup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { MenuComponent } from './menu-components/menu/menu.component';
import { OrdersComponent } from './orders/orders.component';
import { UsersComponent } from './users/users.component';

const routes: Routes = [
  {
    path: '',
    component:LandingComponent
  },
  {
    path: 'sign-in',
    component:SigninComponent
  },
  {
    path: 'sign-up',
    component:SignupComponent
  },
  {
    path: 'dashboard',
    component:DashboardComponent
  },
  {
    path: 'dashboard/profile',
    component:ProfileComponent
  },
  {
    path: 'dashboard/item',
    component:ItemsComponent
  },
  {
    path: 'dashboard/menu',
    component:MenuComponent
  },
  {
    path:'dashboard/orders',
    component:OrdersComponent
  },
  {
    path:'dashboard/users',
    component:UsersComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
