import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';
// import { JwtHelperService } from "@auth0/angular-jwt";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../alert/alert.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  
  public firstName:string;
  public lastName;string;
  public contactNumber:number;
  public email:string;
  public address:string
  public initials:string;
  public userName:string;
  public company:string;
  public role:string ="Admin";//TODO: Hardcoding it for now as we have only 1 role availbale
  public isLoading = false;
  public isEditMode = false;
  public aboutEditForm:FormGroup;

 


  constructor(private userService:UserService,private authenticationService:AuthenticationService,
    private alertService: AlertService) {
    // const helper = new JwtHelperService();
    // const decodedToken = helper.decodeToken(authenticationService.jwt);
    // const userNameCompanyArr = (decodedToken.sub).split("||");
    const userNameCompanyArr = authenticationService.extractUserCompany();
    this.userName = userNameCompanyArr[0];
    this.company = userNameCompanyArr[1];
    // this.authenticationService.UsernameCompany.subscribe(data=>{
    //   this.userName=data.username;
    //   this.company=data.company
    // });
   }

  ngOnInit(): void {
    this.isLoading = true;
    this.alertService.clear();
    this.userService.getUserDetailsUsingUserName(this.userName).subscribe(
      (val)=>{
        this.isLoading=false;
        console.log(JSON.stringify(val));
        this.email=val['userName'];
        this.firstName=val['firstName'];
        this.lastName=val['lastName'];
        this.address=val['address'];
        this.contactNumber=val['phoneNumber'];
        this.initials=this.firstName.charAt(0)+this.lastName.charAt(0);

        // this.aboutEditForm.setValue({
        //   "firstName": this.firstName,
        //   "lastName": this.lastName,
        //   "company": this.company,
        //   "contactNo": this.contactNumber,
        //   "email": this.email,
        //   "role": this.role,
        //   "address": this.address,
        // });

        this.aboutEditForm = new FormGroup({
          "firstName": new FormControl(this.firstName, Validators.required),
          "lastName": new FormControl(this.lastName, Validators.required),
          "company": new FormControl({value: this.company, disabled: true}, Validators.required),
          "contactNo": new FormControl(this.contactNumber, Validators.required),
          "email": new FormControl({value: this.email, disabled: true}, Validators.required),
          "role": new FormControl({value: this.role, disabled: true}, Validators.required),
          "address": new FormControl(this.address, Validators.required),
      });

        
    },
    (error)=>{
      this.isLoading=false;
      console.log("Error")
    }
    );
  }

  enableEditMode(){
    this.isEditMode=!this.isEditMode;
  //  console.log("isEditMode",this.isEditMode);
  }

  aboutEdit(aboutEditForm){
    this.isEditMode=!this.isEditMode;
    this.isLoading = true;
    //console.log("aboutEditForm",aboutEditForm.getRawValue());
    this.userService.updateUserDetailsUsingUserName(aboutEditForm.getRawValue()).subscribe(
      (val)=>{
        this.isLoading=false;
        console.log(JSON.stringify(val));
        this.email=val['userName'];
        this.firstName=val['firstName'];
        this.lastName=val['lastName'];
        this.address=val['address'];
        this.contactNumber=val['phoneNumber'];
        this.initials=this.firstName.charAt(0)+this.lastName.charAt(0);
        
        this.aboutEditForm = new FormGroup({
          "firstName": new FormControl(this.firstName, Validators.required),
          "lastName": new FormControl(this.lastName, Validators.required),
          "company": new FormControl({value: this.company, disabled: true}, Validators.required),
          "contactNo": new FormControl(this.contactNumber, Validators.required),
          "email": new FormControl({value: this.email, disabled: true}, Validators.required),
          "role": new FormControl({value: this.role, disabled: true}, Validators.required),
          "address": new FormControl(this.address, Validators.required),
      });
      this.alertService.success("Profile edit was successful",
      { autoClose: true }
    );
      },
      (error)=>{
        this.isLoading=false;
        //console.log("Update Profile Error")
        this.alertService.error("Profile update failed",
          { autoClose: true }
        );
      }
    );
  }

}
