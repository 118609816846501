<app-header></app-header>
<section class="h-screen pt-16 bg-gradient-to-r from-cyan to-lightBlue">
    <div class="px-4 pt-9">
        <h1 class="text-xl font-bold leading-relaxed text-white lg:text-4xl xs:text-2xl font-primary">
            Get Oota Application.<br>Take your catering business to the next level
        </h1>
        <p class="pt-6 text-sm leading-loose text-white">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore maxime perferendis quae voluptatibus dolor, fuga inventore laborum, delectus doloribus sed voluptas voluptate, aperiam fugit provident aliquid minus unde asperiores et.
        </p>
        <a href="#" routerLink="/sign-up" class="inline-block px-8 py-3 mt-5 text-sm font-bold tracking-wide text-gray-500 uppercase bg-white rounded-full font-primary">Get Now</a>
        <a href="#" class="inline-block px-8 py-3 mt-5 ml-2 text-sm font-bold tracking-wide text-white uppercase border-2 border-white rounded-full font-primary">Know More</a>
    </div>
</section>