<app-header></app-header>
<div class="h-screen pt-16 bg-gradient-to-r from-cyan to-lightBlue">
    <div class="grid px-4 py-6 place-content-center">
        <h1 class="text-xl font-bold leading-relaxed text-white lg:text-4xl xs:text-2xl font-primary">Welcome to <span>Oota</span></h1>
        <p class="text-white font-primary">We are very excited to welcome you to Oota. First 30 days being completely free</p>
    </div>
    <!-- <div class="grid px-4 py-6 md:grid-cols-3 md:gap-2"> -->
      <div class="max-w-xl px-4 mx-auto md:place-content-center">
        <form class="flex flex-col space-y-6" [formGroup]="signUpForm" (ngSubmit)="signUp(signUpForm.value)">
          <div class="flex gap-x-1">
              <input name="firstName" formControlName="firstName" placeholder="First Name" class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
              <input name="lastName" formControlName="lastName" placeholder="Last Name" class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
          </div>
          <input name="companyName" formControlName="companyName" placeholder="Company Name" class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
          <input name="phoneNumber" formControlName="phoneNumber" placeholder="Phone" class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded first-letter:appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
          <input name="email" type="email" formControlName="email" placeholder="Email" class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
          <input name="password" formControlName="password" placeholder="Password" class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
          <button type="submit" [disabled]="!signUpForm.valid" *ngIf="!isLoading" [ngClass]="{'bg-indigo-300':!signUpForm.valid,'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500':signUpForm.valid}" class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md group focus:outline-none focus:ring-2 focus:ring-offset-2">Create</button>
          <div *ngIf="isLoading" class="z-10 flex items-center justify-center space-x-2 animate-pulse">
            <div class="w-8 h-8 bg-gray-800 rounded-full"></div>
            <div class="w-8 h-8 bg-gray-800 rounded-full"></div>
            <div class="w-8 h-8 bg-gray-800 rounded-full"></div>
        </div>
        </form>
      </div>
      <!-- <div class="hidden md:flex md:w-7 md:h-7 md:mx-auto" >
        <img class="md:aspect-square" src="../../assets/images/signup-food.jpg" alt="Hero image of food">
      </div>
        
    </div> -->
</div>
