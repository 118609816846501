import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Menu } from '../pojos/menu';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  public baseURL: string = "https://oota-backend.herokuapp.com/v1/";
  //public baseURL: string = "http://localhost:4200/api";

  constructor(private http: HttpClient) { }

  getAllMenus(){
    if(sessionStorage.getItem('jwt')){
      //const headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('jwt')}
      return this.http.get(this.baseURL+'menuservices/menu/all?pageNum=1&pageSize=100');
    }else{
      throwError("Please login again.");//TODO:Error Snack Bar.
    }
  }

  saveMenu( menuName:string, menuPrice:number,itemIds:number[],discount:number){
    if(sessionStorage.getItem('jwt')){
      //const headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('jwt')}
      let jsonBody = {
        "discount": discount,
        "itemIds": itemIds,
        "name": menuName,
        "price": menuPrice
      };
      return this.http.post(this.baseURL+'menuservices/menu',jsonBody);
    }else{
      throwError("Add Menu failed");//TODO:Error Snack Bar
    }
  }

  editMenu(menuId:number,menuName:string, menuPrice:number,itemIds:number[],discount:number){
      if(sessionStorage.getItem('jwt')){
        let jsonBody = {
          "discount": discount,
          "itemIds": itemIds,
          "name": menuName,
          "price": menuPrice
        };
        return this.http.put(this.baseURL+'menuservices/menu/update/id/'+menuId,jsonBody);
      }else{
        throwError("Edit Menu failed");//TODO:Error Snack Bar
      }
    
  }

  deleteMenu(menu:Menu){
    if(sessionStorage.getItem('jwt')){
      //const headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('jwt')}
      let id:number = menu.id;
      return this.http.delete(this.baseURL+'menuservices/menu/delete/id/'+id);
    }else{
      throwError("Please try deleting menu again");//TODO:Error Snack Bar
    }
  }
}
