<div class="px-4 mx-2 my-2 mt-6 bg-white border border-gray-200 rounded-lg shadow-md sm:mt-4 md:mt-2 lg:mt-0 h-fit">
    <div class="flex justify-end px-4 pt-4 ">
        <button id="dropdownButton" data-dropdown-toggle="dropdown" (click)="toggleDropDown()" class="inline-block text-gray-500  hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-200 rounded-lg text-sm p-1.5" type="button">
            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path></svg>
        </button>
        <!-- Dropdown menu -->
        <div id="dropdown" [ngClass]="{'hidden': !showDropDown, 'block': showDropDown}" class="absolute z-10 hidden mt-10 text-base list-none bg-white divide-y divide-gray-100 rounded shadow -mr-36 w-44 ">
            <ul class="py-1" aria-labelledby="dropdownButton">
            <li>
                <a class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100" (click)="openModal(false)">View</a>
            </li>
            <li>
                <a class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100" (click)="openModal(true)">Edit</a>
            </li>
            <li>
                <a class="block px-4 py-2 text-sm text-red-600 cursor-pointer hover:bg-gray-100" (click)="deleteMenu()">Delete</a>
            </li>
            </ul>
        </div>
    </div>
    <!-- <div class="flex flex-col items-center pb-10">
        <h5 class="mb-1 text-xl text-gray-900 font-primary">{{menu.name}}</h5>
        <span class="text-sm text-gray-500 font-primary">{{menu.type}}</span>
        <ul>
            <li *ngFor="let item of menu.items;" >{{item.name}}</li>
        </ul>
    </div> -->

    <div class="max-w-sm cursor-pointer" (click)="openModal(false)">
            <img class="rounded-t-lg" src="../../../assets/images/Munchies-{{randomInt}}.svg" alt="" />
        <div class="p-5">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">{{menu.name}}</h5>
            <p class="mb-3 font-normal text-gray-700">{{menu.description}}</p>
        </div>
    </div>
</div>
<div *ngIf="toggleModalFlag" class="fixed inset-0 z-50 flex items-center justify-center w-full h-full overflow-x-hidden overflow-y-auto fade">
    <div class="relative w-auto mx-auto">
        <div class="flex flex-col w-full bg-white rounded shadow-2xl">
            <menudetails (closeModalEvent)="closeModal()" (editedMenu)="editedMenu($event)" [isEditMode]="isEditMode" [isNewMenuMode]="false" [menu]="menu" class="w-full h-full max-w-xl mx-auto lg:max-w-2xl"></menudetails>
            <!-- <div class="text-2xl font-bold text-black">Header</div>
            <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut dicta corporis earum aliquid praesentium magnam sit. Minus obcaecati aut delectus, magnam ex, vero saepe sit laudantium nisi commodi, molestias quo.</span>-->
        </div>
    </div>
</div>
<div *ngIf="toggleModalFlag" class="absolute inset-0 z-40 bg-black opacity-25"></div>
