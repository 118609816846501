import { throwError } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  public isLoading:boolean=false;
  signUpForm = new FormGroup({
    "firstName": new FormControl("", Validators.required),
    "lastName": new FormControl("", Validators.required),
    "companyName": new FormControl("", Validators.required),
    "phoneNumber": new FormControl("", Validators.required),
    "email": new FormControl("", Validators.required),
    "password": new FormControl("", Validators.required),
});

  constructor(private authService:AuthenticationService,private router: Router) { }

  ngOnInit(): void {
  }

  signUp(value){
    console.log("signup",value);
    this.isLoading=true;
    this.authService.createNewCompany(value.firstName,
      value.lastName,
      value.companyName,
      value.phoneNumber,
      value.email,
      value.password).subscribe(
      (data)=>{
        this.isLoading=false;
        this.router.navigate(['/sign-in']);
      },
      (error)=>{
        throwError("Unable to create Company")
      }
    )
  }

}
