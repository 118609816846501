<div *ngIf="!isEditMode && !isNewMenuMode" class="w-full h-full p-8 overflow-x-hidden overflow-y-auto bg-white rounded-sm shadow-sm">
    <div class="container flex items-center space-x-2 font-semibold leading-8 text-gray-900">

        <span class="text-green-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
            </svg>
        </span>
        <span class="text-2xl font-bold leading-relaxed tracking-wide text-gray-800 lg:text-4xl xs:text-3xl font-primary">{{menu.name}}</span>
    </div>
    <div class="container text-gray-700">
        <div class="grid text-sm md:grid-cols-2">
            <div class="grid grid-cols-1">
                        <div class="px-4 py-2 font-semibold">Id</div>
                        <div class="px-4 py-2">{{menu.id}}</div>
            </div>
            <div class="grid grid-cols-1">
                <div class="px-4 py-2 font-semibold">Location</div>
                <div class="px-4 py-2">{{menu.location}}</div>
            </div>
            <div class="grid grid-cols-1">
                <div class="px-4 py-2 font-semibold">Price</div>
                <div class="px-4 py-2">{{menu.price}}</div>
            </div>
            <div class="grid grid-cols-1">
                <div class="px-4 py-2 font-semibold">Discount</div>
                <div class="px-4 py-2">{{menu.discount}}</div>
            </div>
        </div>
    </div>
    <div class="container mt-6 font-primary">
        <span class="text-xl font-bold leading-relaxed tracking-wide text-gray-800 lg:text-3xl xs:text-2xl font-primary">Items</span>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-sm text-left text-gray-500">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                        <th scope="col" class="px-6 py-3">
                            Name
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Price
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of menu.items" class="bg-white border-b hover:bg-gray-50 ">
                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                            {{item.name}}
                        </th>
                        <td class="px-6 py-4">
                            {{item.price}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
