import { Item } from 'src/app/pojos/item';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  public baseURL: string = "https://oota-backend.herokuapp.com/v1/";
  //public baseURL: string = "http://localhost:4200/api";

  constructor(private http: HttpClient) { }

  getAllItems(){
    if(sessionStorage.getItem('jwt')){
      //const headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('jwt')}
      return this.http.get(this.baseURL+'itemservices/item/all?pageNum=1&pageSize=100');
    }else{
      throwError("Please login again.");//TODO:Error Snack Bar.
    }
  }

  addItem(itemName:string, itemPrice:number){
    if(sessionStorage.getItem('jwt')){
      //const headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('jwt')}
      let jsonBody = {
        "itemName": itemName,
        "itemPrice": itemPrice
      }
      return this.http.post(this.baseURL+'itemservices/item',jsonBody);
    }else{
      throwError("Add Item failed");//TODO:Error Snack Bar
    }
  }

  editItem(id:number,itemName:string, itemPrice:number ){
    if(sessionStorage.getItem('jwt')){
      let jsonBody = {
        "itemName": itemName,
        "itemPrice": itemPrice
      }
      return this.http.put(this.baseURL+'itemservices/item/update/id/'+id,jsonBody);
    }else{
      throwError("Edit Item failed");//TODO:Error Snack Bar
    }
  }

  deleteItem(item:Item){
    if(sessionStorage.getItem('jwt')){
      //const headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('jwt')}
      let id:number = item.id;
      return this.http.delete(this.baseURL+'itemservices/item/delete/id/'+id);
    }else{
      throwError("Please login again.");//TODO:Error Snack Bar
    }
  }



  
}
