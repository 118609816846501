<div class="h-full p-8 overflow-x-hidden overflow-y-auto bg-white rounded-sm shadow-sm">
    <form name="changePasswordForm" id="changePasswordForm" [formGroup]="changePasswordForm" (ngSubmit)="changePasswordSubmit()">
        <div class="flex items-center space-x-2 font-semibold leading-8 text-gray-900">
            <h1  
            class="text-2xl font-bold leading-relaxed tracking-wide text-gray-800 lg:text-4xl xs:text-3xl font-primary">Change Password</h1>
        </div>
        <div class="pt-2 text-gray-700">
            <div class="grid grid-cols-1 text-sm">
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 text-xl font-semibold">Current Password</div>
                    <input id="current-password" type="password" formControlName="currentPassword" name="currentPassword" class="px-4 py-2 leading-tight border-2 border-gray-200 rounded focus:outline-none focus:border-none" value="CurrentPassword" placeholder="******************" readonly>
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 text-xl font-semibold">New Password</div>
                    <input  id="new-password" type="password" formControlName="newPassword" name="newPassword" class="px-4 py-2 leading-tight border-2 border-gray-200 rounded focus:outline-none focus:bg-white focus:border-cyan" placeholder="New Password">
                    <input id="retype-newpassword" type="password" formControlName="reTypeNewPassword" name="reTypeNewPassword" class="px-4 py-2 mt-2 leading-tight border-2 border-gray-200 rounded focus:outline-none focus:bg-white focus:border-border-cyan" placeholder="ReType New Password">
                </div>
            </div>
        </div>
    </form>
</div>

<footer class="flex flex-row-reverse w-full h-16 bg-slate-200">
    <button class="px-3 text-sm font-semibold text-green-800 rounded-sm w-fit mx-7 hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs" (click)="toggleModal()">Close</button>
    <button type="submit" form="changePasswordForm" class="px-3 text-sm font-semibold text-blue-800 rounded-sm w-fit mx-7 hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs">Save</button>
</footer>