import { ItemService } from './../services/item.service';
import { Component, OnInit } from '@angular/core';
import { Item } from '../pojos/item';
import { map } from 'rxjs/internal/operators/map';
import { Utils } from '../utils';
import { throwError } from 'rxjs';
import { AlertService } from '../alert/alert.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {
  public items:Array<Item> = [];
  public isLoading = false;
  public toggleNewItemModalFlag:boolean=false;
  public toggleEditItemModalFlag:boolean=false;
  public currentEditItem:Item = null;

  constructor(private itemService:ItemService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.itemService
    .getAllItems()
    .pipe(map(res=>Object.values(res)))
    .subscribe({
      next:(items)=>{
        this.isLoading = false;
        //console.log("items",items);
        this.items=Utils.getItemsList(items);
      },
      error:(err)=>{
        //TODO: Eat error for now, later escape only specific errors
        this.isLoading = false;
        console.log(err);
      }
    });
  }

  openNewItemModal(){
    this.toggleNewItemModalFlag=true;
  }

  closeNewItemModal(){
    this.toggleNewItemModalFlag=false;
  }

  openEditItemModal(currentEditItem:Item){
    this.currentEditItem=currentEditItem;
    this.toggleEditItemModalFlag=true;
  }

  closeEditItemModal(){
    this.toggleEditItemModalFlag=false;
    this.currentEditItem=null;
  }

  deleteItem(deletedItem:Item){
    console.log("item",deletedItem);
    this.itemService.deleteItem(deletedItem).subscribe(()=>{
      this.items = this.items.filter(item => item.id!=deletedItem.id);
      this.alertService.clear();
      this.alertService.success("Successfully deleted Item",
      { autoClose: true });
    },
    (error)=>{
      this.alertService.clear();
      this.alertService.error("Unable to delete item",
      { autoClose: true });
      throwError("Unable to delete Item");
    })
  }

  updatedOrCreatedItem(newOrEditedItem:Item){
    let itemExistsFlag:boolean = this.items.some((item) =>  {
      if(item.id === newOrEditedItem.id){
        console.log("newItem in true",item.id);
        item.name=newOrEditedItem.name;
        item.price=newOrEditedItem.price;
        return true;
      }
    });

    if(!itemExistsFlag){
      console.log("newItem in itemsComponent",newOrEditedItem);
      this.items.push(newOrEditedItem);
    }
    this.toggleNewItemModalFlag=false;
    this.toggleEditItemModalFlag=false;
    this.currentEditItem=null;
  }

}
