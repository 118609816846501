import { ItemService } from './../../services/item.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Item } from 'src/app/pojos/item';
import { throwError } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';

@Component({
  selector: 'newitem',
  templateUrl: './new-item.component.html',
  styleUrls: ['./new-item.component.scss']
})
export class NewItemComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter<boolean>();
  @Output("updatedOrCreatedItem") updatedOrCreatedItem = new EventEmitter<Item>();
  @Input("item") item:Item;
  @Input("isEditMode") isEditMode:boolean;
  @Input("isNewItemMode") isNewItemMode:boolean;
  public itemEditForm:FormGroup;
  public randomInt:number=0;
  
  constructor(private fb:FormBuilder, private itemService:ItemService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.randomInt=Math.floor((Math.random() * 6) + 1);
    if(this.item!=null){
      this.itemEditForm = this.fb.group({
        "itemName": [this.item.name, Validators.required],
        "itemPrice": [this.item.price, Validators.required]
        });
    }else{
      this.itemEditForm = this.fb.group({
        "itemName": [null, Validators.required],
        "itemPrice": [null, Validators.required]
        });
    }
  }

  toggleModal(){
    this.closeModalEvent.emit();
  }

  itemEdit(){
    // console.log("Test");
    //console.log("itemEditForm",itemEditForm.getRawValue());
    this.itemService.editItem(this.item.id,this.itemEditForm.get('itemName').value,this.itemEditForm.get('itemPrice').value)
    .subscribe((data)=>{
      console.log("editItem",data);
      this.alertService.clear();
      this.alertService.success("Successfully edited Item",
      { autoClose: true });
      let editItem = new Item(data["itemId"],data["itemName"], data["itemPrice"]);
      this.updatedOrCreatedItem.emit(editItem);
    },
    (error)=>{
      this.alertService.clear();
      this.alertService.error("Item edit failed",
      { autoClose: true });
      throwError("Unable to edit Item");
    });
  }

  itemNewForm(){
   // console.log("newitemEditForm",this.itemEditForm.getRawValue());
    this.itemService.addItem(this.itemEditForm.get('itemName').value,this.itemEditForm.get('itemPrice').value).subscribe(
      (data)=>{
        console.log("newItem",data);
        let newItem = new Item(data["itemId"],data["itemName"], data["itemPrice"]);
        this.alertService.clear();
        this.alertService.success("Successfully created Item",
        { autoClose: true });
        this.updatedOrCreatedItem.emit(newItem);
      },
      (error)=>{
        this.alertService.clear();
        this.alertService.error("Unable to create new Item",
        { autoClose: true });
        throwError("Unable to create Item");
      }
    )
  }

}
