import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from "@auth0/angular-jwt";
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public baseURL: string = "https://oota-backend.herokuapp.com/v1/";
  //public baseURL: string = "http://localhost:4200/api";
  // private usernameCompanySubject:Subject<any> = new BehaviorSubject<any>({});

  constructor(private http: HttpClient,) { }

  extractUserCompany():string[]{
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.jwt);
    return (decodedToken.sub).split("||");
  }

  login(company:string,username:string,password:string):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body=
      {
        "username":username,
        "password":password,
        "company":company
    }
    return this.http.post(this.baseURL+'authenticate',body,{'headers':headers}); //TODO: jwt token going with this call. Fix it
  }

  createNewCompany(firstName:string,
    lastName:string,
    companyName:string,
    phoneNumber:string,
    email:string,
    password:string){
    const headers = { 'content-type': 'application/json'}  
    const body=
    {
      "companyName": companyName,
      "email": email,
      "firstName": firstName,
      "lastName": lastName,
      "password":password,
      "phoneNumber": phoneNumber
    }
    return this.http.post(this.baseURL+'companyservices/company',body,{'headers':headers});

  }

  forgotPassword(
    companyName:string,
    email:string){
    const headers = { 'content-type': 'application/json'}  
    return this.http.put(this.baseURL+'authenticate/forgotpassword/'+companyName+'/'+email,{'headers':headers});
  }

  changePassword(newPassword:string){
    let username:String=this.extractUserCompany()[0];
    const body =newPassword;
    const headers = { 'content-type': 'text/plain'}  
    return this.http.put(this.baseURL+'userservices/user/password/'+username,body,{'headers':headers});
  }

  setJWT(token:string){
    sessionStorage.setItem('jwt',token);
  }

  get jwt():string{
    return sessionStorage.getItem('jwt');
  }

  deleteJWT(){
    sessionStorage.removeItem('jwt');
  }

  // get UsernameCompany():Observable<any>{
  //   return this.usernameCompanySubject.asObservable();
  // }

  // setUsernameCompany(username:string, company:string){
  //   this.usernameCompanySubject.next({
  //     "username":username,
  //     "company":company
  //   });
  // }
}
