<login-header></login-header>
<alert class="flex items-center justify-center"></alert>
<section *ngIf="!isLoading" class="h-screen pt-16 bg-gradient-to-r from-cyan to-lightBlue lg:pl-72">
    <div class="relative bg-repeat-x h-1/5 lg:h-2/5 bg-profile-pattern">
        <img class="absolute z-10 w-16 h-16 rounded-full md:w-24 md:h-24 -bottom-8 left-2" src="https://avatars.dicebear.com/api/initials/{{initials}}.svg"/>
    </div>
    <div class="h-full p-8 bg-white rounded-sm shadow-sm">
        <div class="flex items-center space-x-2 font-semibold leading-8 text-gray-900">
            <span clas="text-green-500">
                <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
            </span>
            <span class="tracking-wide">About</span>
        </div>
        <div class="text-gray-700">
            <div *ngIf="!isEditMode"  class="grid text-sm md:grid-cols-3">
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">First Name</div>
                    <div class="px-4 py-2">{{firstName}}</div>
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Last Name</div>
                    <div class="px-4 py-2">{{lastName}}</div>
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Company</div>
                    <div class="px-4 py-2">{{company}}</div>
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Contact No.</div>
                    <div class="px-4 py-2">{{contactNumber}}</div>
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Email.</div>
                    <div class="px-4 py-2">
                        <a class="text-blue-800" href="mailto:{{email}}">{{email}}</a>
                    </div>
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Role</div>
                    <div class="px-4 py-2">Admin</div>
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Address</div>
                    <div class="px-4 py-2">{{address}}</div>
                </div>
            </div>
            
            <form *ngIf="isEditMode" name="aboutEditForm" id="aboutEditForm" [formGroup]="aboutEditForm" class="grid gap-4 text-sm md:grid-cols-3" (ngSubmit)="aboutEdit(aboutEditForm)">
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">First Name</div>
                    <input id="first-name" formControlName="firstName" name="first-name" type="text" class="px-4 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Last Name</div>
                    <input id="last-name" formControlName="lastName" name="last-name" type="text" class="px-4 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Company</div>
                    <input id="company" formControlName="company" name="company" type="text" class="px-4 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md opacity-50 appearance-none cursor-not-allowed sm:text-sm">
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Contact No.</div>
                    <input id="contactNo" formControlName="contactNo" name="contactNo" type="text" class="px-4 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Email</div>
                    <input id="email" formControlName="email" name="email" type="text" class="px-4 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md opacity-50 appearance-none cursor-not-allowed sm:text-sm">
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Role</div>
                    <input id="role" formControlName="role" name="role" type="text" class="px-4 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md opacity-50 appearance-none cursor-not-allowed sm:text-sm">
                </div>
                <div class="grid grid-cols-1">
                    <div class="px-4 py-2 font-semibold">Address</div>
                    <textarea id="address" formControlName="address" name="address" type="text" class="px-4 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none resize-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"></textarea>
                </div>
            </form>
        </div>
    </div>
</section>
<footer *ngIf="!isLoading" class="fixed bottom-0 flex flex-row-reverse w-full h-16 bg-slate-200">
    <button *ngIf="!isEditMode" (click)="enableEditMode()" class="px-3 text-sm font-semibold text-blue-800 rounded-sm w-fit mx-7 hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs">Edit</button>
    <button *ngIf="isEditMode" type="submit" form="aboutEditForm" class="px-3 text-sm font-semibold text-blue-800 rounded-sm w-fit mx-7 hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs">Save</button>
</footer>
<div *ngIf="isLoading" class="z-10 flex items-center justify-center h-screen pt-16 space-x-2 lg:pl-72 animate-pulse">
    <div class="w-8 h-8 bg-gray-800 rounded-full"></div>
    <div class="w-8 h-8 bg-gray-800 rounded-full"></div>
    <div class="w-8 h-8 bg-gray-800 rounded-full"></div>
</div>
