<header class="fixed inset-0 z-50 flex items-center justify-between h-16 px-3 bg-gray-800 lg:bg-gradient-to-r from-cyan to-lightBlue">
    <div class="hidden lg:flex fixed flex-col mt-16 top-0 left-0 bg-gray-800 w-[35vw] h-full p-4 lg:w-72">
        <a href="#" routerLink="/dashboard/item" class="px-3 py-2 text-sm text-white font-primary">Item</a>
        <a href="#" routerLink="/dashboard/menu" class="px-3 py-2 text-sm text-white font-primary">Menu</a>
        <a href="#" routerLink="/dashboard/orders" class="px-3 py-2 text-sm text-white font-primary">Orders</a>
        <a href="#" routerLink="/dashboard/users" class="px-3 py-2 text-sm text-white font-primary">Users</a>
    </div>
    <nav class="flex items-center h-16 text-white lg:hidden">
        <button class="top-0 left-0" type="button" (click)="toggleMenu()">
            <svg xmlns="http://www.w3.org/2000/svg" [ngClass]="{'hidden': showNavBar, 'flex': !showNavBar}"
            class="h-8 px-3 py-1 rounded-sm" fill="none" viewBox="0 0 24 24" 
            stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" [ngClass]="{'hidden': !showNavBar, 'flex': showNavBar}"
        class="h-8 px-3 py-1 rounded-sm" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h7" />
        </svg>
        </button>
    </nav>
    <img src="../../assets/images/logo.svg" alt="logo" class="md:h-6 lg:h-10"/>
    <div class="inline-flex w-12 h-12 cursor-pointer md:w-14 md:h-14" (click)="toggleProfileDropDown()">
        <img class="border border-gray-100 rounded-full shadow-sm" src="https://avatars.dicebear.com/api/initials/{{initials}}.svg" alt="user image" />
        <div id="dropdown" [ngClass]="{'hidden': !showProfileDropDown, 'block': showProfileDropDown}"
        class="absolute z-10 hidden mt-16 -ml-32 text-base list-none bg-white divide-y divide-gray-100 rounded shadow w-44">
            <ul class="py-1" aria-labelledby="dropdownButton">
                <li>
                    <a class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100" routerLink="/dashboard">Home</a>
                </li>
                <li>
                    <a class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100" routerLink="/dashboard/profile">Profile</a>
                </li>
                <li>
                    <a class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100" (click)="openChangePasswordModal()">Change Password</a>
                </li>
                <li>
                    <a class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100" routerLink="/" (click)="logout()">Log Out</a>
                </li>
            </ul>
        </div>
    </div>
    <!-- Dropdown menu -->

    <!-- <div class="inline-flex space-x-4 text-sm text-white hover:text-gray-800 lg:text-black lg:text-base lg:space-x-6 font-primary">
        <a routerLink="/" (click)="logout()">Log Out</a>
    </div> -->
</header>
<div [ngClass]="{'hidden': !showNavBar, 'flex': showNavBar}" class="z-50 lg:hidden fixed flex-col mt-16 top-0 left-0 bg-gray-800 w-[35vw] h-full p-4">
    <a href="#" routerLink="/dashboard/item" class="px-3 py-2 text-sm text-white font-primary">Item</a>
    <a href="#" routerLink="/dashboard/menu" class="px-3 py-2 text-sm text-white font-primary">Menu</a>
    <a href="#" routerLink="/dashboard/orders" class="px-3 py-2 text-sm text-white font-primary">Orders</a>
    <a href="#" routerLink="/dashboard/users" class="px-3 py-2 text-sm text-white font-primary">Users</a>
</div>

<div *ngIf="toggleChangePasswordModal" class="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto">
    <div class="relative w-auto mx-auto">
        <div class="flex flex-col w-full bg-white rounded shadow-2xl">
            <change-password (closeModalEvent)="closeChangePasswordModal()"></change-password>
        </div>
    </div>
</div>
<div *ngIf="toggleChangePasswordModal" class="absolute inset-0 z-40 bg-black opacity-25"></div>