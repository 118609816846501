import { Component, OnInit } from '@angular/core';
import { AlertService } from '../alert/alert.service';
import { UserService } from '../services/user.service';
import { map } from 'rxjs/internal/operators/map';
import { User } from '../pojos/user';
import { Utils } from '../utils';
import { throwError } from 'rxjs';

@Component({
  selector: 'users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  public isLoading:boolean = false;
  public toggleNewUserModalFlag:boolean = false;
  public users:Array<User> = []; 

  constructor(private userService:UserService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.userService
    .getAllUsers()
    .pipe(map(res=>Object.values(res)))
    .subscribe({
      next:(user)=>{
        this.isLoading = false;
        //console.log("items",items);
        this.users=Utils.getUserList(user);
        console.log(this.users);
      },
      error:(err)=>{
        //TODO: Eat error for now, later escape only specific errors
        this.isLoading = false;
        console.log(err);
      }
    });
  }

  openUserModal(){
    this.toggleNewUserModalFlag=true;
  }

  closeModal(){
    this.toggleNewUserModalFlag=false;
  }

  deleteUser(deletedUser:User){
    this.userService.deleteUser(deletedUser).subscribe(()=>{
      this.users = this.users.filter(user => user.userId!=deletedUser.userId);
      this.alertService.clear();
      this.alertService.success("Successfully deleted User",
      { autoClose: true });
    },
    (error)=>{
      this.alertService.clear();
      this.alertService.error("Unable to delete user",
      { autoClose: true });
      throwError("Unable to delete User");
    })
  }

  createdNewUser(newUser:User){
    this.users.push(newUser);
    this.toggleNewUserModalFlag=false;
  }

}
