import { Menu } from "./menu";

export class Order{
    public id:number
    public status:string;
    public statusCode:StatusCodes;
    public customerId:number;
    public menus:Menu[];

    constructor(id:number,status:string,statusCode:StatusCodes, customerId:number,menus:Menu[]){
        this.id=id;
        this.status=status; // active = 1 pause =2 complete =3 and cancelled =4 
        this.statusCode=statusCode;
        this.customerId=customerId;
        this.menus=menus;
    }
    public static getOrderStatus(statusCode:StatusCodes):string{
        switch(statusCode){
            case StatusCodes.active://1
                return "active";
                break;
            case StatusCodes.pause://2
                return "pause";
                break;
            case StatusCodes.complete://3
                return "complete";
                break;
            case StatusCodes.cancelled://4
                return "cancelled";
                break;
            default:// Default is 1  
                return "active";      
        }
    }
}

// active = 1 pause =2 complete =3 and cancelled =4 
export enum StatusCodes{
    active = 1,
    pause = 2,
    complete = 3,
    cancelled = 4
}