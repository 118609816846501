export class User{
    public userId:number;
    public userName:string;
    public firstName:string;
    public lastName:string;
    public company:string;
    public contactNum:number;
    public email:string;
    public role:string;
    public address:string;

    constructor(
        userId:number,
        userName:string,
        firstName:string,
        lastName:string,
        company:string,
        contactNum:number,
        email:string,
        role:string,
        address:string){
        this.userId=userId;
        this.userName=userName;
        this.firstName=firstName;
        this.lastName=lastName;
        this.company=company;
        this.contactNum=contactNum;
        this.email=email;
        this.role=role;
        this.address=address;
    }
}