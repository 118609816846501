import { MenuService } from './../../services/menu.service';
import { Component, OnInit } from '@angular/core';
import { Item } from '../../pojos/item';
import { Menu } from '../../pojos/menu';
import { map } from "rxjs/operators";
import { Utils } from 'src/app/utils';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public menus:Array<Menu> = [];
  public isLoading = false;
  public toggleModalFlag:boolean = false;
  // public isNewMenuMode:boolean = false;
  // public isNewMenuMode:boolean=false;

  // public menuNewForm:FormGroup = this.fb.group({
  //   "menuName": [null, Validators.required],
  //   "menuPrice": [null, Validators.required],
  //   "menuDiscount": [null, Validators.required],
  //   "items":this.fb.array([],Validators.required)
  //   });

  constructor(private menuService:MenuService, private fb:FormBuilder) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.menuService
    .getAllMenus()
    .pipe(map(res=>Object.values(res)))
    .subscribe({
      next: (menus)=>{
        this.isLoading = false;
      //  console.log("menus",menus);
        this.menus=Utils.getMenusList(menus);
      },
      error: (err)=>{
        //TODO: Eat error for now, later escape only specific errors
        this.isLoading = false;
        console.log(err);
      }
    });
  }


  openModal(isNewMenuMode:boolean){
    this.toggleModalFlag=true;
  }

  closeModal(){
    this.toggleModalFlag=false;
  }

  // menuNew(menuNewForm){
  //   // console.log("Test");
  //   console.log("menuNewFormMenu",menuNewForm.getRawValue());
  // }


  // createItemForm(item:Item){
  //   const itemForm = this.fb.group({
  //     itemName:[item.name,Validators.required],
  //     itemPrice:[item.price,Validators.required]
  //   })

  //   this.items.push(itemForm);
  // }

  // addItem(){
  //   let item:Item = new Item("",0);
  //   this.createItemForm(item);
  // }
  
  // get items():FormArray{
  //   return this.menuNewForm.controls["items"] as FormArray;
  // }

  // deleteItem(itemIndex:number){
  //   this.items.removeAt(itemIndex);
  // }

  // addMenu(){
  //   this.isNewMenuMode=!this.isNewMenuMode;
  //   console.log("!this.isNewMenuMode",this.isNewMenuMode)
  // }

  deletedMenuId(deletedMenuId:number){
    this.menus=this.menus.filter((menu)=>menu.id!=deletedMenuId);
  }

  createdMenu(newMenu:Menu){
    this.toggleModalFlag=false;
    this.menus.push(newMenu);
    
  }
}
