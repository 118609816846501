import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import {ButtonModule} from 'primeng/button';
import { SigninComponent } from './signin/signin.component';
import { HeaderComponent } from './header/header.component';
import { SignupComponent } from './signup/signup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginHeaderComponent } from './login-header/login-header.component';
import { ProfileComponent } from './profile/profile.component';
import { MenuComponent } from './menu-components/menu/menu.component';
import { MenuCardComponent } from './menu-components/menucard/menucard.component';
import { MenudetailsComponent } from './menu-components/menudetails/menudetails.component';
import {TableModule} from 'primeng/table';
import { OrdersComponent } from './orders/orders.component';
import {AccordionModule} from 'primeng/accordion';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtModule } from '@auth0/angular-jwt';
import { NeworderComponent } from './orders/neworder/neworder.component';
import { ItemsComponent } from './items/items.component';
import { NewItemComponent } from './items/new-item/new-item.component';
import { AlertModule } from './alert/alert.module';
import { ChangePasswordComponent } from './login-header/change-password/change-password.component';
import { UsersComponent } from './users/users.component';
import { NewuserComponent } from './users/newuser/newuser.component';
import { InvoicesComponent } from './invoices/invoices.component';


export function tokenGetter() {
  return sessionStorage.getItem('jwt');
}
@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    SigninComponent,
    HeaderComponent,
    SignupComponent,
    DashboardComponent,
    LoginHeaderComponent,
    ProfileComponent,
    MenuComponent,
    MenuCardComponent,
    MenudetailsComponent,
    OrdersComponent,
    NeworderComponent,
    ItemsComponent,
    NewItemComponent,
    ChangePasswordComponent,
    UsersComponent,
    NewuserComponent,
    InvoicesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ButtonModule,
    TableModule,
    AccordionModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["oota-backend.herokuapp.com"]
      },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
