import { Item } from "./pojos/item";
import { Menu } from "./pojos/menu";
import { User } from './pojos/user';
import { AuthenticationService } from "./services/authentication.service";

export class Utils {
    static company:string=null;
    constructor(private authenticationService:AuthenticationService){
        const userNameCompanyArr = authenticationService.extractUserCompany();
        Utils.company=userNameCompanyArr[1];
    }

    public static getItemsList(itemsResponse): Array<Item> {
        let items: Array<Item> = [];
        if (itemsResponse.length > 0) {
            Object.values(itemsResponse).forEach((item) => {
                let newItem = new Item(item["itemId"],item["itemName"], item["itemPrice"]);
                items.push(newItem);
            });
        }
        return items;
    }

    public static getUserList(userResponse): Array<User> {
        let users: Array<User> = [];
        if (userResponse.length > 0) {
            Object.values(userResponse).forEach((user) => {
                let role:string = (user["roleId"]==1)?'Admin':'User';// if roleId=1 then its Admin, if roleId=2 , then its User role
                let userItem = new User(
                    user["userId"],
                    user["userName"], 
                    user["firstName"],
                    user["lastName"],
                    this.company,
                    user["phoneNumber"],
                    user["userName"],
                    role,
                    user["address"],
                    );
                users.push(userItem);
            });
        }
        return users;

        // public userId:number;
        // public userName:string;
        // public firstName:string;
        // public lastName:string;
        // public company:string;
        // public contactNum:number;
        // public email:string;
        // public role:string;
        // public address:string;

    }

    public static getMenusList(menusResponse): Array<Menu> {
        let menus: Array<Menu> = [];
        if (menusResponse.length > 0) {
            Object.values(menusResponse).forEach((menu) => {
            let newMenu = new Menu(
                menu["menuName"],
                this.getItemsList(menu["items"]),
                menu["locationId"],
                menu["menuPrice"],
                menu["menuDiscount"],
                menu["menuId"],
                "Test description Test description test Description test description"
            );
            menus.push(newMenu);
            });
        }
        return menus;
    }


}
