<login-header></login-header>
<alert class="flex items-center justify-center"></alert>
<section *ngIf="!isLoading" class="flex flex-col gap-2 pt-16 bg-white lg:pl-72">
        <button (click)="openNewOrderModal()" class="flex items-center px-4 py-2 mx-4 mt-6 ml-6 font-bold text-white bg-green-800 rounded hover:bg-green-700 max-w-fit">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>Add</span>
        </button>
        <p-accordion [multiple]="true" class="w-full mt-4">
            <p-accordionTab *ngFor="let order of orders;" header="Order #{{order.id}}">
                <div class="grid grid-cols-1 gap-2 sm:grid-cols-3">
                    <div class="flex sm:col-span-2">
                        <div class="px-4 py-2 font-semibold">Status</div>
                        <div class="px-4 py-2">{{order.status}}</div>
                    </div>
                    <div class="grid grid-cols-3 gap-1">
                        <button (click)="pauseUnpause(order)" *ngIf="order.status=='active'" class="flex items-center px-4 py-2 ml-6 font-bold text-yellow-800 bg-yellow-300 rounded sm:ml-0 max-w-fit hover:bg-yellow-400">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <!-- <span>Pause</span> -->
                        </button>
                        <button (click)="pauseUnpause(order)" *ngIf="order.status=='pause'" class="flex items-center px-4 py-2 ml-6 font-bold text-green-800 bg-green-300 rounded sm:ml-0 max-w-fit hover:bg-green-400">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <!-- <span>Continue</span> -->
                        </button>
                        <div class="flex gap-0.5">
                            <button id="editOrderButton" *ngIf="order.status!='complete'" (click)="openEditOrderModal(order)" class="flex items-center px-4 py-2 ml-6 font-bold bg-green-300 rounded sm:ml-0 max-w-fit hover:bg-green-400">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                </svg>
                            </button>
                            <button id="completeOrderButton" (click)="completeOrder(order)" *ngIf="order.status==('active' || 'pause') && (order.status!='complete')" class="flex items-center px-4 py-2 ml-6 font-bold text-yellow-800 bg-green-300 rounded sm:ml-0 max-w-fit hover:bg-green-400">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <!-- <span>Pause</span> -->
                            </button>
                            <button id="deleteOrderButton" *ngIf="order.status!='complete'" (click)="deleteOrder(order)" class="flex items-center px-4 py-2 ml-6 font-bold bg-red-300 rounded sm:ml-0 max-w-fit hover:bg-red-400">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-1 gap-2 mt-8 sm:grid-cols-3">
                    <div class="sm:col-span-2">
                        <div class="flex items-center space-x-2 font-semibold leading-8 text-gray-900">
                            <span class="text-green-500">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                </svg>
                            </span>
                            <span class="text-xl font-bold leading-relaxed tracking-wide text-gray-800 lg:text-2xl xs:text-l font-primary">Menus</span>
                    </div>
                        <ul class="mt-2 ml-8 font-primary">
                            <li *ngFor="let menu of order.menus"><a (click)="openMenuModal(menu)" class="px-3 py-2 text-sm text-gray-800 cursor-pointer hover:text-cyan">{{menu.name}}</a></li>
                        </ul>
                    </div>
                    <div class="flex mt-6 text-pink-800">
                            <div class="px-4 py-2 font-semibold">Customer Id:</div>
                            <div class="px-4 py-2">{{order.customerId}}</div>
                    </div>
                </div>   
            </p-accordionTab>
        </p-accordion>
</section>
<div *ngIf="!isLoading" class="max-w-xs mx-auto lg:max-w-lg md:max-w-md sm:block sm:max-w-sm">
    <img src="../../assets/images/order-illustration.svg"/>
</div>
<div *ngIf="toggleModalFlag" class="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto">
    <div class="relative w-auto mx-auto">
        <div class="flex flex-col w-full bg-white rounded shadow-2xl">
            <menudetails (closeModalEvent)="closeMenuModal()" [menu]="menu" [isEditMode]="false" [isNewMenuMode]="false" class="max-w-xl mx-auto lg:max-w-2xl"></menudetails>
        </div>
    </div>
</div>
<div *ngIf="toggleModalFlag" class="absolute inset-0 z-40 bg-black opacity-25"></div>

<div *ngIf="toggleNewOrderModalFlag" class="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto">
    <div class="relative w-auto mx-auto">
        <div class="flex flex-col w-full bg-white rounded shadow-2xl">
            <!-- <menudetails (closeModalEvent)="closeMenuModal()" [menu]="menu" class="max-w-xl mx-auto lg:max-w-2xl"></menudetails> -->
            <neworder [isEditMode]="false" [isNewMode]="true" (closeModalEvent)="closeNewOrderModal()" (updatedOrCreatedOrder)="updatedOrCreatedOrder($event)"></neworder>
        </div>
    </div>
</div>
<div *ngIf="toggleNewOrderModalFlag" class="absolute inset-0 z-40 bg-black opacity-25"></div>


<div *ngIf="toggleEditOrderModalFlag" class="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto">
    <div class="relative w-auto mx-auto">
        <div class="flex flex-col w-full bg-white rounded shadow-2xl">
            <!-- <menudetails (closeModalEvent)="closeMenuModal()" [menu]="menu" class="max-w-xl mx-auto lg:max-w-2xl"></menudetails> -->
            <neworder [isEditMode]="true" [isNewMode]="false"  (closeModalEvent)="closeEditOrderModal()" (updatedOrCreatedOrder)="updatedOrCreatedOrder($event)" [order]="currentEditOrder"></neworder>
        </div>
    </div>
</div>
<!-- <div *ngIf="toggleNewOrderModalFlag" class="absolute inset-0 z-40 bg-black opacity-25"></div> -->

<div *ngIf="isLoading" class="z-10 flex items-center justify-center h-screen pt-16 space-x-2 lg:pl-72 animate-pulse">
    <div class="w-8 h-8 bg-gray-800 rounded-full"></div>
    <div class="w-8 h-8 bg-gray-800 rounded-full"></div>
    <div class="w-8 h-8 bg-gray-800 rounded-full"></div>
</div>
    