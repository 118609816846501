<header class="fixed inset-0 flex items-center justify-between h-16 px-3 bg-gray-800 md:bg-gradient-to-r from-cyan to-lightBlue">
    <img src="../../assets/images/logo.svg" alt="logo" class="md:h-6 lg:h-10"/>
    <nav class="flex items-center h-16 text-white">
        <button class="md:hidden" type="button" (click)="toggleNavbar()"><svg xmlns="http://www.w3.org/2000/svg" class="h-8 px-3 py-1 border rounded-sm" fill="none" viewBox="0 0 24 24" 
            stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
          </svg></button>
    </nav>
    <div class="hidden space-x-4 text-sm text-white md:inline-flex lg:text-base lg:space-x-6 font-primary">
        <a href="#">Home</a>
        <a href="#">Pricing</a>
        <a href="#">Blog</a>
        <a href="#">About Us</a>
        <a href="#" routerLink="/sign-in">Sign In</a>
    </div>
</header>
<div [ngClass]="{'hidden': !showMenu, 'flex': showMenu}" class="fixed z-20 flex-col w-full h-16 mx-auto mt-16 bg-gray-800 md:hidden">
<!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
<a href="#" class="px-3 py-2 text-sm text-white bg-gray-800 font-primary">Home</a>
<a href="#" class="px-3 py-2 text-sm text-white bg-gray-800 font-primary">Pricing</a>
<a href="#" class="px-3 py-2 text-sm text-white bg-gray-800 font-primary">Blog</a>
<a href="#" class="px-3 py-2 text-sm text-white bg-gray-800 font-primary">About Us</a>
<a href="#" routerLink="/sign-in" class="px-3 py-2 text-sm text-white bg-gray-800 font-primary">Sign In</a>
</div>