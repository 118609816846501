import { User } from './../pojos/user';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public baseURL: string = "https://oota-backend.herokuapp.com/v1/";
  //public baseURL: string = "http://localhost:4200/api";
  

  constructor(private http: HttpClient,private authenticationService:AuthenticationService) { }

  getUserDetailsUsingUserName(userName){
    if(sessionStorage.getItem('jwt')){
      //const headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('jwt')}
      return this.http.get(this.baseURL+'userservices/user/'+userName);
    }else{
      throwError("Please login again.");//TODO:Handle this better
    }
  }

  getAllUsers(){
    if(sessionStorage.getItem('jwt')){
      //const headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('jwt')}
      return this.http.get(this.baseURL+'userservices/user/all?pageNum=1&pageSize=100');
    }else{
      throwError("Please login again.");//TODO:Error Snack Bar.
    }
  }

  updateUserDetailsUsingUserName(data){
    if(sessionStorage.getItem('jwt')){
      const userNameCompanyArr = this.authenticationService.extractUserCompany();
      const headers = { 'content-type': 'application/json'}
      const body=
      {
        "address": data.address,
        "firstName": data.firstName,
        "lastName": data.lastName
      }
      //const headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('jwt')}
      return this.http.put(this.baseURL+'userservices/user/update/'+userNameCompanyArr[0],body,{'headers':headers});
    }else{
      throwError("Please login again.");//TODO:Handle this better
    }
  }

  createNewUser(data){
    if(sessionStorage.getItem('jwt')){
      // const userNameCompanyArr = this.authenticationService.extractUserCompany();
      const headers = { 'content-type': 'application/json'}
      const body=
      {
        "address": data.address,
        "email": data.email,
        "firstName": data.firstName,
        "lastName": data.lastName,
        "phoneNumber": data.contactNo,
        "username": data.email
      }
      return this.http.post(this.baseURL+'userservices/user/',body,{'headers':headers});
    }else{
      throwError("Please login again.");//TODO:Handle this better
    }
  }


  deleteUser(user:User){
    if(sessionStorage.getItem('jwt')){
      // const userNameCompanyArr = this.authenticationService.extractUserCompany();
      const headers = { 'content-type': 'application/json'}
      return this.http.delete(this.baseURL+'userservices/user/delete/'+user.userName,{'headers':headers});
    }else{
      throwError("Please login again.");//TODO:Handle this better
    }
  }

  
}
