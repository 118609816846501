import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { Menu } from '../pojos/menu';
import { Order } from '../pojos/order';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  public baseURL: string = "https://oota-backend.herokuapp.com/v1/";
  //public baseURL: string = "http://localhost:4200/api";

  constructor(private http: HttpClient) { }

  getAllOrders():Observable<any>{
    if(sessionStorage.getItem('jwt')){
      //const headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('jwt')}
      return this.http.get(this.baseURL+'orderservices/order/all?pageNum=1&pageSize=10');
    }else{
      throwError("Please login again.");//TODO:Error Snack Bar.
    }
  }

  saveOrder(menuIds:number[],customerId:number){
    if(sessionStorage.getItem('jwt')){
      //const headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('jwt')}
      let jsonBody = { 
        "customerId": customerId,
        "menuIds": menuIds
        };
      return this.http.post(this.baseURL+'orderservices/order',jsonBody);
    }else{
      throwError("Add Item failed");//TODO:Error Snack Bar
    }
  }

  editOrder(orderId:number, statusCode:number, customerId:number,menuIds:number[]){
      if(sessionStorage.getItem('jwt')){
        let orderStatusBoolean:boolean = false;
        // if(orderStatus == "active"){ //TODO: Change orderStatus from boolean to string so that three states are there in backend
        //   orderStatusBoolean=true;
        // }
        let jsonBody = { 
          "statusCode":statusCode,
          "customerId": customerId,
          "menuIds": menuIds
          };
        return this.http.put(this.baseURL+'orderservices/order/update/id/'+orderId,jsonBody);
      }else{
        throwError("Edit Order failed");//TODO:Error Snack Bar
      }
  }

  updateStatus(orderId: number, statusCode:number){
    if(sessionStorage.getItem('jwt')){
      //const headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('jwt')}
     // let id:number = order.id;
      return this.http.put(this.baseURL+'orderservices/order/update/status/'+orderId+'?statusCode='+statusCode,null);
    }else{
      throwError("Please login again.");//TODO:Error Snack Bar
    }
  
  }

  deleteOrder(order:Order){
    if(sessionStorage.getItem('jwt')){
      //const headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('jwt')}
      let id:number = order.id;
      return this.http.delete(this.baseURL+'orderservices/order/delete/id/'+id);
    }else{
      throwError("Please login again.");//TODO:Error Snack Bar
    }
  }

}
