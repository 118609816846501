import { StatusCodes } from 'src/app/pojos/order';
import { OrderService } from "./../services/order.service";
import { Component, OnInit } from "@angular/core";
import { Menu } from "../pojos/menu";
import { Order } from "../pojos/order";
import { map } from "rxjs/operators";
import { Utils } from "../utils";
import { throwError } from "rxjs/internal/observable/throwError";
import { AlertService } from '../alert/alert.service';

@Component({
  selector: "orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"],
})
export class OrdersComponent implements OnInit {
  public toggleModalFlag: boolean = false;
  public toggleNewOrderModalFlag:boolean = false;
  public toggleEditOrderModalFlag:boolean = false;
 // public isPause:boolean=false;
  //public showDropDown: boolean = false;
  public isLoading = false;
  public menu: Menu;
  public orders: Array<Order> = [];
  public currentEditOrder:Order;
  constructor(private orderService: OrderService,
    private alertService: AlertService) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.orderService
      .getAllOrders()
      .pipe(map((res) => Object.values(res)))
      .subscribe({
        next: (orders) => {
          this.isLoading = false;
          //console.log("orders", orders);
          orders.forEach((order) => {
            let newOrder = new Order(
              order["orderId"],
              Order.getOrderStatus(order["statusCode"]),
              order["statusCode"],
              order["customerId"],
              Utils.getMenusList(order["menus"])
            );
            this.orders.push(newOrder);
          });
        },
        error: (err) => {
          //TODO: Eat error for now, later escape only specific errors
          this.isLoading = false;
          console.log(err);
        },
      });
  }

  // toggleDropDown() {
  //   this.showDropDown = !this.showDropDown;
  // }

  openMenuModal(menu: Menu) {
    this.toggleModalFlag = true;
  //  this.showDropDown = false;
    this.menu = menu;
  }

  closeMenuModal() {
    this.toggleModalFlag = false;
    this.menu=null;
  }

  openNewOrderModal(){
    this.toggleNewOrderModalFlag=true;
  }

  closeNewOrderModal(){
    this.toggleNewOrderModalFlag=false;
  }

  openEditOrderModal(currentEditOrder:Order){
    this.currentEditOrder=currentEditOrder;
    this.toggleEditOrderModalFlag=true;
  }

  closeEditOrderModal(){
    this.currentEditOrder=null;
    this.toggleEditOrderModalFlag=false;
  }

  deleteOrder(deletedOrder:Order){
    //console.log("delete Order",deletedOrder);
    this.orderService.updateStatus(deletedOrder.id,4)
    .subscribe((data) => {
      this.orderService.deleteOrder(deletedOrder)
      .subscribe((data)=>{
        this.alertService.clear();
        this.alertService.success("Order successfully deleted",
        { autoClose: true });
        this.orders=this.orders.filter((order)=>order.id!=deletedOrder.id);
      },
      (error)=>{
        this.alertService.clear();
        this.alertService.error("Unable to delete orders",
        { autoClose: true });
        throwError("Unable to delete order");
      });
    },
    (error)=>{
      throwError("Unable to change status to cancelled");
    });
  }

  pauseUnpause(order:Order){
   // this.isPause=!this.isPause;
    let statusCode:number;
    let orderStatus:string;
    if(order.status==Order.getOrderStatus(1)){
      statusCode=StatusCodes.pause;
      orderStatus=Order.getOrderStatus(2);
    }else if(order.status==Order.getOrderStatus(2)){
      statusCode=StatusCodes.active;
      orderStatus=Order.getOrderStatus(1);
    }
    this.orderService.updateStatus(order.id,order.statusCode)
    .subscribe((data) => {
      console.log("Status changed to either active or paused successfully");
      order.statusCode=statusCode;
      order.status=orderStatus;
      if(order.status=='pause'){
        this.alertService.clear();
        this.alertService.success("Order successfully Paused",
        { autoClose: true });
      }else{
        this.alertService.clear();
        this.alertService.success("Order successfully Resumed",
        { autoClose: true });
      }
    },
    (error)=>{
      this.alertService.clear();
      this.alertService.error("Unable to Pause order",
      { autoClose: true });
      throwError("Unable to change status to either active or paused");
    });
  }

  completeOrder(order:Order){
    console.log("Completed Order");
    this.orderService.updateStatus(order.id,3)
    .subscribe((data) => {
      this.alertService.clear();
      this.alertService.success("Order successfully Completed",
      { autoClose: true });
      order.statusCode=StatusCodes.complete;
      order.status=Order.getOrderStatus(3);
      console.log("Status changed to complete successfully");
    },
    (error)=>{
      this.alertService.error("Unable to Complete order",
      { autoClose: true });
      throwError("Unable to change status to either active or paused");
    });
  }

  updatedOrCreatedOrder(newOrEditedOrder:Order){
    let orderExistsFlag:boolean = this.orders.some((order) =>  {
      if(order.id === newOrEditedOrder.id){
        console.log("editOrder in ordersComponent",order.id);
        order.id=newOrEditedOrder.id;
        order.customerId=newOrEditedOrder.customerId;
        order.status=newOrEditedOrder.status;
        order.menus=newOrEditedOrder.menus;
        return true;
      }
    });

    if(!orderExistsFlag){
      console.log("newOrder in ordersComponent",newOrEditedOrder);
      this.orders.push(newOrEditedOrder);
    }
    this.toggleNewOrderModalFlag=false;
    this.toggleEditOrderModalFlag=false;
    this.currentEditOrder=null;
  }
}
