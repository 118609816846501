import { AlertService } from './../../alert/alert.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter<boolean>();
  public changePasswordForm:FormGroup;

  constructor(private fb:FormBuilder,
    private authenticationService:AuthenticationService,
    private alertService:AlertService) { }

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      "currentPassword": [null, Validators.required],
      "newPassword": [null, Validators.required],
      "reTypeNewPassword": [null, Validators.required]
      });
  }

  toggleModal(){
    this.closeModalEvent.emit();
  }

  changePasswordSubmit(){
    if(this.changePasswordForm.get('currentPassword').value!=null 
    && this.changePasswordForm.get('newPassword').value!=null 
    && this.changePasswordForm.get('reTypeNewPassword').value!=null  ){
      this.authenticationService.changePassword(this.changePasswordForm.get('newPassword').value)
      .subscribe(()=>{
        this.closeModalEvent.emit();
        this.alertService.clear();
        this.alertService.success("Successfully Changed Password",
        { autoClose: true });
      },
      (error)=>{
        this.closeModalEvent.emit();
        this.alertService.clear();
        this.alertService.error("Unable to change Password",
      { autoClose: true });
      });
    }
  }
  
}
