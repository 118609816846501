<login-header></login-header>
<alert class="flex items-center justify-center"></alert>
<section *ngIf="!isLoading" class="grid grid-cols-1 pt-16 sm:grid-col-2 md:grid-cols-3 lg:grid-cols-4 bg-gradient-to-r from-cyan to-lightBlue lg:pl-72">
    <!-- <menu-card *ngFor="let menu of menus;" [title]="menu.name" [subTitle]="menu.type" [items]="menu.items"></menu-card> -->
    
    <div class="flex justify-center h-48 px-4 mx-2 mt-6 bg-white border border-gray-200 rounded-lg shadow-md sm:mt-4 md:mt-2 lg:mt-0">
        <button type="button" (click)="openModal()" id="addMenuButton" class="text-gray-500  hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-200 rounded-lg text-sm p-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-16 h-16" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" />
            </svg>
        </button>
    </div>
    
    <menu-card *ngFor="let menu of menus;" [menu]="menu" (deletedMenuId)="deletedMenuId($event)"></menu-card>
</section>

<div *ngIf="toggleModalFlag" class="fixed inset-0 z-50 flex items-center justify-center w-full h-full overflow-x-hidden overflow-y-auto fade">
    <div class="relative w-auto mx-auto">
        <div class="flex flex-col w-full bg-white rounded shadow-2xl">
            <menudetails (closeModalEvent)="closeModal()" (createdMenu)="createdMenu($event)" [isEditMode]="true" [isNewMenuMode]="true" class="w-full max-w-xl mx-auto lg:max-w-2xl"></menudetails>
        </div>
    </div>
</div>

<div *ngIf="toggleModalFlag" class="absolute inset-0 z-40 bg-black opacity-25"></div>


<div *ngIf="isLoading" class="z-10 flex items-center justify-center h-screen pt-16 space-x-2 lg:pl-72 animate-pulse">
    <div class="w-8 h-8 bg-gray-800 rounded-full"></div>
    <div class="w-8 h-8 bg-gray-800 rounded-full"></div>
    <div class="w-8 h-8 bg-gray-800 rounded-full"></div>
</div>
