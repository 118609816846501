<app-header></app-header>
<alert class="flex items-center justify-center"></alert>
<div class="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8 bg-gradient-to-r from-cyan to-lightBlue">
    <div class="w-full max-w-md space-y-8">
      <div>
        <img class="w-auto h-12 mx-auto" src="../../assets/images/logo-large.svg" alt="Logo">
        <h2 class="mt-6 text-3xl font-extrabold text-center text-white">Sign in to your account</h2>
        <p class="mt-2 text-sm text-center text-gray-600">
          Or
          <a routerLink="/sign-up" class="font-medium text-indigo-600 hover:text-indigo-500"> Sign Up </a>
        </p>
      </div>
      <form [formGroup]="signinForm" class="mt-8 space-y-6" (ngSubmit)="signIn(signinForm.value)">
        <div class="rounded-md shadow-sm">
          <div class="">
            <input id="company-name" formControlName="companyName" name="company-name" required type="text" required class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Company Name">
          </div>
          <div class="">
            <input id="email" name="email" formControlName="email" type="email" required class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address">
          </div>
          <div class="">
            <input id="password" name="password" formControlName="password" type="password" required class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password">
          </div>
        </div>
  
        <div class="flex items-center justify-between">
          <div class="text-sm">
            <a class="font-medium text-indigo-600 cursor-pointer hover:text-indigo-500" (click)="forgotPassword(signinForm.value)"> Forgot your password? </a>
          </div>
        </div>
        
        <div>
          <button type="submit" [disabled]="!signinForm.valid" *ngIf="!isLoading" [ngClass]="{'bg-indigo-300':!signinForm.valid,'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500':signinForm.valid}" class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md group focus:outline-none focus:ring-2 focus:ring-offset-2">
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <svg class="w-5 h-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
              </svg>
            </span>
            Sign in
          </button>
          <div *ngIf="isLoading" class="z-10 flex items-center justify-center space-x-2 animate-pulse">
            <div class="w-8 h-8 bg-gray-800 rounded-full"></div>
            <div class="w-8 h-8 bg-gray-800 rounded-full"></div>
            <div class="w-8 h-8 bg-gray-800 rounded-full"></div>
        </div>
        </div>
      </form>
    </div>
  </div>