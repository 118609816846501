<login-header></login-header>
<alert class="flex items-center justify-center"></alert>
<section *ngIf="!isLoading" class="flex flex-col gap-2 pt-16 overflow-x-auto bg-white lg:pl-72">
    <button (click)="openNewItemModal()" class="flex items-center px-4 py-2 mx-4 mt-6 ml-6 font-bold text-white bg-green-800 rounded hover:bg-green-700 max-w-fit">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span>Add</span>
    </button>
    <table class="w-full text-sm text-left text-gray-500">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
                <th scope="col" class="px-6 py-3">
                    Id
                </th>
                <th scope="col" class="px-6 py-3">
                    Name
                </th>
                <th scope="col" class="px-6 py-3">
                    Price
                </th>
                <th scope="col" class="px-6 py-3">
                    <span class="sr-only">Edit</span>
                </th>
                <th scope="col" class="px-6 py-3">
                    <span class="sr-only">Delete</span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of items" class="bg-white border-b hover:bg-gray-50">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    {{item.id}}
                </th>
                <td class="px-6 py-4">
                    {{item.name}}
                </td>
                <td class="px-6 py-4">
                    {{item.price}}
                </td>
                <td class="px-6 py-4 text-right">
                    <a (click)="openEditItemModal(item)" class="font-medium text-blue-600 cursor-pointer hover:underline">Edit</a>
                </td>
                <td class="px-6 py-4 text-right">
                    <a (click)="deleteItem(item)" class="font-medium text-blue-600 cursor-pointer hover:underline">Delete</a>
                </td>

                

            </tr>
        </tbody>
    </table>
</section>

<div *ngIf="isLoading" class="z-10 flex items-center justify-center h-screen pt-16 space-x-2 lg:pl-72 animate-pulse">
    <div class="w-8 h-8 bg-gray-800 rounded-full"></div>
    <div class="w-8 h-8 bg-gray-800 rounded-full"></div>
    <div class="w-8 h-8 bg-gray-800 rounded-full"></div>
</div>

<div *ngIf="toggleNewItemModalFlag" class="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto">
    <div class="relative w-auto mx-auto">
        <div class="flex flex-col w-full bg-white rounded shadow-2xl">
            <newitem [isEditMode]="true" [isNewItemMode]="true" (closeModalEvent)="closeNewItemModal()" (updatedOrCreatedItem)="updatedOrCreatedItem($event)"></newitem>
        </div>
    </div>
</div>
<div *ngIf="toggleNewItemModalFlag" class="absolute inset-0 z-40 bg-black opacity-25"></div>

<div *ngIf="toggleEditItemModalFlag" class="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto">
    <div class="relative w-auto mx-auto">
        <div class="flex flex-col w-full bg-white rounded shadow-2xl">
            <newitem [isEditMode]="true" [isNewItemMode]="false" [item]="currentEditItem" (closeModalEvent)="closeEditItemModal()" (updatedOrCreatedItem)="updatedOrCreatedItem($event)"></newitem>
        </div>
    </div>
</div>
<div *ngIf="toggleEditItemModalFlag" class="absolute inset-0 z-40 bg-black opacity-25"></div>