import { Item } from "./item";

export class Menu{
    public name:string;
    public items: Item[];
    public location:string;
    public price:number;
    public discount:number;
    public id:number;
    public description:string;

    constructor(name:string,items:Item[],
        location:string,price:number,discount:number,
        id:number,description:string){
        this.name=name;
        this.items=items;
        this.location=location;
        this.price=price;
        this.discount=discount;
        this.id=id;
        this.description=description;
    }
}

