import { MenuService } from './../../services/menu.service';
import { ItemService } from './../../services/item.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Item } from '../../pojos/item';
import { Menu } from '../../pojos/menu';
import { map } from 'rxjs/internal/operators/map';
import { Utils } from 'src/app/utils';
import { throwError } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';

@Component({
  selector: 'menudetails',
  templateUrl: './menudetails.component.html',
  styleUrls: ['./menudetails.component.scss']
})
export class MenudetailsComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter<boolean>();
  @Input("menu") menu:Menu;
  @Input("isEditMode") isEditMode:boolean
  @Input("isNewMenuMode") isNewMenuMode:boolean;
  @Output("createdMenu") createdMenu = new EventEmitter<Menu>();
  @Output("editedMenu") editedMenu = new EventEmitter<Menu>();
  public menuEditForm:FormGroup;
  public availableItems:Item[];

  constructor(private fb:FormBuilder,private itemService:ItemService,
    private menuService:MenuService,private alertService: AlertService) { }

  ngOnInit(): void {
    this.itemService
    .getAllItems()
    .pipe(map(res=>Object.values(res)))
    .subscribe({
      next: (items)=>{
        this.availableItems=Utils.getItemsList(items);
      },
      error: (err)=>{//TODO: Have better error handling
        //this.isLoading = false;
        this.alertService.clear();
        this.alertService.error("Unable to load menu details",
        { autoClose: true });
        console.log(err);
      }
    });

    if(this.menu!=null){
      this.menuEditForm = this.fb.group({
        "menuName": [this.menu.name, Validators.required],
        "menuPrice": [this.menu.price, Validators.required],
        "menuDiscount": [this.menu.discount, Validators.required],
        "items":this.fb.array([],Validators.required)
        });
        this.menu.items.forEach(item => {
          this.createItemForm(item);
        });
    }else{
      this.menuEditForm = this.fb.group({
        "menuName": [null, Validators.required],
        "menuPrice": [null, Validators.required],
        "menuDiscount": [null, Validators.required],
        "items":this.fb.array([],Validators.required)
        });
    }

  }

  toggleModal(){
    this.closeModalEvent.emit();
  }

  menuEdit(menuEditForm){
   // console.log("Test");
    console.log("menuEditForm",menuEditForm.getRawValue());
    //console.log("orderNewForm",orderNewForm.getRawValue());
    let selectedItems = this.menuEditForm.get('items').value;
    let menuName = this.menuEditForm.get('menuName').value;
    let menuPrice = this.menuEditForm.get('menuPrice').value;
    let menuDiscount = this.menuEditForm.get('menuDiscount').value;
    let selectedItemsIds:number[]=[];
    selectedItems.forEach(selectedItem =>{
      selectedItemsIds.push(selectedItem.itemSelect.id);
    });
    this.menuService.editMenu(this.menu.id,menuName,menuPrice,selectedItemsIds,menuDiscount)
    .subscribe((data)=>{
      console.log("editMenu",data);
      this.alertService.clear();
      this.alertService.success("Menu edit was successful",
      { autoClose: true });
      let newMenuItems:Item[]=Utils.getItemsList(data["items"]);
      
      // data["menus"].forEach(menuResponse => {
       
      //   newOrderMenuIds.push(menuResponse["menuId"]);
      // });
      let newMenu:Menu = new Menu(data["menuName"], newMenuItems,
        data["locationId"], data["menuPrice"], data["menuDiscount"], data["menuId"],"Test Description Test DEscription");//TODO: Hard-coding Menu type as Non-Veg. Need to add support to backend
      this.editedMenu.emit(newMenu);
    },
    (error)=>{
      this.alertService.clear();
      this.alertService.error("Unable to edit Menu",
      { autoClose: true });
      throwError("Unable to create new Menu");
    });

  }

  menuNewForm(menuEditForm){
    console.log("newMenuEditForm",menuEditForm.getRawValue());
    //console.log("orderNewForm",orderNewForm.getRawValue());
    let selectedItems = this.menuEditForm.get('items').value;
    let menuName = this.menuEditForm.get('menuName').value;
    let menuPrice = this.menuEditForm.get('menuPrice').value;
    let menuDiscount = this.menuEditForm.get('menuDiscount').value;
    let selectedItemsIds:number[]=[];
    selectedItems.forEach(selectedItem =>{
      selectedItemsIds.push(selectedItem.itemSelect.id);
    });
    this.menuService.saveMenu(menuName,menuPrice,selectedItemsIds,menuDiscount)
    .subscribe((data)=>{
      console.log("newMenu",data);
      this.alertService.clear();
      this.alertService.success("New Menu successfully created",
      { autoClose: true });
      let newMenuItems:Item[]=Utils.getItemsList(data["items"]);
      
      // data["menus"].forEach(menuResponse => {
       
      //   newOrderMenuIds.push(menuResponse["menuId"]);
      // });
      let newMenu:Menu = new Menu(data["menuName"],  newMenuItems,
        data["locationId"], data["menuPrice"], data["menuDiscount"], data["menuId"],"Test Description Test Description Test Description");//TODO: Hard-coding Menu type as Non-Veg. Need to add support to backend
      this.createdMenu.emit(newMenu);
    },
    (error)=>{
      this.alertService.clear();
      this.alertService.error("Unable to create new Menu",
      { autoClose: true });
      throwError("Unable to create new Menu");
    });
  }


  createItemForm(item:Item){
    // const itemForm = this.fb.group({
    //   itemName:[item.name,Validators.required],
    //   itemPrice:[item.price,Validators.required]
    // })

    // this.items.push(itemForm);
    let itemForm = null;
    if(item!=null){
      itemForm = this.fb.group({
        itemSelect:[item,Validators.required]
      })
    }else{
      itemForm = this.fb.group({
        itemSelect:[null,Validators.required]
      })
    }
    this.items.push(itemForm);

  }

  addItem(){
    let item:Item = new Item(0,"",0);
    this.createItemForm(item);
  }
  get items():FormArray{
    return this.menuEditForm.controls["items"] as FormArray;
  }

  deleteItem(itemIndex:number){
    this.items.removeAt(itemIndex);
  }

}
