import { OrderService } from './../../services/order.service';
import { MenuService } from './../../services/menu.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Menu } from 'src/app/pojos/menu';
import { Order, StatusCodes } from 'src/app/pojos/order';
import { map } from 'rxjs/internal/operators/map';
import { Utils } from 'src/app/utils';
import { throwError } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';

@Component({
  selector: 'neworder',
  templateUrl: './neworder.component.html',
  styleUrls: ['./neworder.component.scss']
})
export class NeworderComponent implements OnInit {

  public orderNewForm:FormGroup;
  @Output() closeModalEvent = new EventEmitter<boolean>();
  @Output("updatedOrCreatedOrder") updatedOrCreatedOrder = new EventEmitter<Order>();
  @Input("order") order:Order;
  @Input("isEditMode") isEditMode:boolean;
  @Input("isNewMode") isNewMode:boolean;

  // menu1:Menu = new Menu("test1","Veg",null,"1",123,2,10);
  // menu2:Menu = new Menu("test2","Veg",null,"1",123,2,10);
  // menu3:Menu = new Menu("test2","Veg",null,"1",123,2,10);
  menusList:Menu[];
  

  constructor(private fb:FormBuilder, private menuService:MenuService,
     private orderService:OrderService, private alertService: AlertService) { 
    //this.menusList=[this.menu1, this.menu2, this.menu3];
  }

  ngOnInit(): void {
    this.menuService
    .getAllMenus()
    .pipe(map(res=>Object.values(res)))
    .subscribe({
      next: (menus)=>{
        this.menusList=Utils.getMenusList(menus);
      },
      error: (err)=>{//TODO: Have better error handling
        //this.isLoading = false;
        console.log(err);
      }
    });

    this.orderNewForm = this.fb.group({
      "menus":this.fb.array([],Validators.required)
    });

    if(this.order!=null){
      this.order.menus.forEach(menu => {
        this.createOrderForm(menu);
      });
    }
}

  toggleModal(){
    this.closeModalEvent.emit();
  }

  submitOrderNewForm(){
    //console.log("orderNewForm",orderNewForm.getRawValue());
    let selectedMenus = this.orderNewForm.get('menus').value;
    let selectedMenuIds:number[]=[];
    selectedMenus.forEach(selectedMenu =>{
      selectedMenuIds.push(selectedMenu.menuSelect.id);
    });
    this.orderService.saveOrder(selectedMenuIds,1)//TODO : Passing a hard coded customer Id
    .subscribe((data)=>{
      console.log("newOrder",data);
      this.alertService.clear();
      this.alertService.success("Successfully created new Order",
      { autoClose: true });
      let newOrderMenuIds:Menu[]=Utils.getMenusList(data["menus"]);
      
      // data["menus"].forEach(menuResponse => {
      //   newOrderMenuIds.push(menuResponse["menuId"]);
      // });
      let newOrder:Order = new Order(data["orderId"],Order.getOrderStatus(StatusCodes.active),StatusCodes.active, 1,newOrderMenuIds);//TODO: Order status is hardcoded to "active". Need to add to DB and fetch from DB
      this.updatedOrCreatedOrder.emit(newOrder);
    },
    (error)=>{
      this.alertService.clear();
      this.alertService.error("Unable to create new Order",
      { autoClose: true });
      throwError("Unable to create new Order");
    });
  }

  submitOrderEditForm(){
  //  console.log("orderEditForm",orderEditForm.getRawValue());
    let selectedMenus = this.orderNewForm.get('menus').value;
    let selectedMenuIds:number[]=[];
    selectedMenus.forEach(selectedMenu =>{
      selectedMenuIds.push(selectedMenu.menuSelect.id);
    });

    this.orderService.editOrder(this.order.id,this.order.statusCode,1,selectedMenuIds) //TODO:: Passing a hard coded customer Id
    .subscribe((data)=>{
      //console.log("editOrder",data);
      this.alertService.clear();
      this.alertService.success("Order edit was successful",
      { autoClose: true });
      let editOrderMenuIds:Menu[]=Utils.getMenusList(data["menus"]);      
      let newOrder:Order = new Order(data["orderId"],Order.getOrderStatus(this.order.statusCode),this.order.statusCode,1,editOrderMenuIds);//TODO: Order status is hardcoded to "active". Need to add to DB and fetch from DB
      this.updatedOrCreatedOrder.emit(newOrder); 
    },
    (error)=>{
      this.alertService.clear();
      this.alertService.error("Unable to edit Order",
      { autoClose: true });
      throwError("Unable to edit Order");
    });

  }

  createOrderForm(menu:Menu){
    let menuForm = null;
    if(menu!=null){
      menuForm = this.fb.group({
        menuSelect:[menu,Validators.required]
      })
    }else{
      menuForm = this.fb.group({
        menuSelect:[null,Validators.required]
      })
    }


    this.menus.push(menuForm);
  }

  addMenu(){
    //let menu:Menu = new Menu("",0);
    //let menu:Menu = 
    this.createOrderForm(null);
  }
  get menus():FormArray{
    return this.orderNewForm.controls["menus"] as FormArray;
  }

  deleteMenu(menuIndex:number){
    this.menus.removeAt(menuIndex);
  }

}
