import { MenuService } from './../../services/menu.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Item } from 'src/app/pojos/item';
import { Menu } from 'src/app/pojos/menu';
import { throwError } from 'rxjs/internal/observable/throwError';
import { AlertService } from 'src/app/alert/alert.service';

@Component({
  selector: 'menu-card',
  templateUrl: './menucard.component.html',
  styleUrls: ['./menucard.component.scss']
})
export class MenuCardComponent implements OnInit {
  public toggleModalFlag:boolean = false;
  @Input("menu") menu:Menu;
  @Output("deletedMenuId") deletedMenuId = new EventEmitter<number>();
  

  public showDropDown:boolean = false;
  public isEditMode:boolean = false;
  public randomInt:number=0;

  // @Input("menus") menus:Menu[];

  constructor(private menuService:MenuService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.randomInt=Math.floor((Math.random() * 10) + 1);
  }

  toggleDropDown(){
    this.showDropDown=!this.showDropDown;
  }

  openModal(isEditMode:boolean){
    this.toggleModalFlag=true;
    this.showDropDown=false;
    this.isEditMode=isEditMode;
  }

  closeModal(){
    this.toggleModalFlag=false;
  }

  deleteMenu(){
    // console.log("delete Menu",this.menu);
    // this.menuService.deleteOrder(deletedOrder)
    // .subscribe((data)=>{
    //   this.orders=this.orders.filter((order)=>order.id!=deletedOrder.id);
    // },
    // (error)=>{
    //   throwError("Unable to delete order");
    // })

    this.menuService.deleteMenu(this.menu)
    .subscribe((data)=>{
      this.alertService.clear();
      this.alertService.success("Successfully deleted menu",
      { autoClose: true });
      this.deletedMenuId.emit(this.menu.id);
    },
    (error)=>{
      this.alertService.clear();
      this.alertService.error("Unable to delete menu",
      { autoClose: true });
      throwError("Unable to delete menu");
    });
  }

  editedMenu(editedMenu:Menu){
    this.menu=editedMenu;
    this.toggleModalFlag = false;
  }

}
