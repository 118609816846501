import { AuthenticationService } from '../services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '../alert/alert.service';

@Component({
  selector: 'signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  public isLoading:boolean = false;
  signinForm = new FormGroup({
    "companyName": new FormControl("", Validators.required),
    "email": new FormControl("", Validators.required),
    "password": new FormControl("", Validators.required),
});

  constructor(private authService:AuthenticationService,private router: Router, private alertService: AlertService) { }
  ngOnInit(): void {
    this.alertService.clear();
  }

  signIn(value){
    this.isLoading = true;
    this.authService.login(value.companyName ,value.email,value.password).subscribe(
      (val) => {
        this.isLoading=false;
        this.authService.setJWT(val.jwt);
       // this.authService.setUsernameCompany(value.email,value.companyName);
        this.router.navigate(['/dashboard']);
      },
     (error) => {
      this.isLoading=false;
      this.alertService.error("Sign in failed.",
      { autoClose: true }
    );
      }
    );
  }

  forgotPassword(value){
    this.isLoading = true;
    if(value!=null && value.companyName!=null && value.companyName!="" && value.email!=null && value.email!=""){
      let companyName = value.companyName;
      let email = value.email
      this.authService.forgotPassword(companyName,email).subscribe(
        (val)=>{
          this.isLoading=false;
        this.alertService.success("Successfully reset password. Please check your email for further instructions.",
        { autoClose: true });
        }
      ),
      (error) => {
        this.isLoading=false;
        this.alertService.error("Unable to reset password. Please contact administrator.",
        { autoClose: true }
      );
      }
    }else{
      this.isLoading=false;
      this.alertService.error("Company Name and email cannot be empty",
        { autoClose: true }
      );
    }
    
  }

}
