import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.scss']
})
export class LoginHeaderComponent implements OnInit {
  public showNavBar:boolean = false;
  public showProfileDropDown:boolean = false;
  public firstName:string;
  public lastName;string;
  public initials:string;
  public userName:string;
  public isEditMode = false;
  public toggleChangePasswordModal:boolean = false;

  constructor(private authService:AuthenticationService,private router: Router,private userService:UserService) { 
    const userNameCompanyArr = authService.extractUserCompany();
    this.userName = userNameCompanyArr[0];
  }

  ngOnInit(): void {
    this.userService.getUserDetailsUsingUserName(this.userName).subscribe(
      (val)=>{
        this.firstName=val['firstName'];
        this.lastName=val['lastName'];
        this.initials=this.firstName.charAt(0)+this.lastName.charAt(0);
    },
    (error)=>{
      console.log("Error")
    }
    );
  }

  toggleMenu(){
    this.showNavBar=!this.showNavBar;
  }

  logout(){
    this.authService.deleteJWT();
    //this.router.navigate(['/']);
  }

  toggleProfileDropDown(){
    this.showProfileDropDown=!this.showProfileDropDown;
  }

  openChangePasswordModal(){
    this.toggleChangePasswordModal=true;
  }

  closeChangePasswordModal(){
    this.toggleChangePasswordModal=false;
  }


}
